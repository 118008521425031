import { Suspense, lazy } from "react";
import Skeleton from "react-loading-skeleton";
import DefaultMeta from "src/component/meta/DefaultMeta";

const Setting = lazy(async () => {
  return import("src/pages/setting/Setting");
});

const LazySetting = () => {
  return (
    <>
      <DefaultMeta
        title="Am-I-Ai / Setting"
        name="Am-I-Ai / Setting"
        content="This is Am-I-Ai Setting page"
      />
      <Suspense fallback={<Skeleton />}>
        <Setting />
      </Suspense>
    </>
  );
};

export { LazySetting };

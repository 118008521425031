import React from "react";
import { Helmet } from "react-helmet";

const DefaultMeta = ({
  title,
  name,
  content,
  url = "https://am-i-ai.com",
  image = "https://am-i-ai.com/baseResized.png",
}: {
  title: string;
  name: string;
  content: string;
  url?: string;
  image?: string;
}) => {
  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};

export default DefaultMeta;

import { Suspense, lazy } from "react";
import Skeleton from "react-loading-skeleton";
import DefaultMeta from "src/component/meta/DefaultMeta";

const NotFound = lazy(async () => {
  return import("src/pages/notFound/NotFound");
});

const LazyNotFound = () => {
  return (
    <>
      <DefaultMeta
        title="Am-I-Ai / notFound"
        name="Am-I-Ai / notFound"
        content="This is Am-I-Ai notFound page"
      />
      <Suspense fallback={<Skeleton />}>
        <NotFound />
      </Suspense>
    </>
  );
};

export { LazyNotFound };
